import React, { useRef } from 'react';

import logo from './img/logo_final.png';

function App() {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div className="App">
      <div className="container-fluid hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="px-4 pt-5 text-center">
                <img className="d-block mx-auto mb-4" src={logo} width="144" />
                <h1 className="display-5 fw-bold">
                  <span>Entre em forma, ganhe confiança, mude a sua vida</span>
                </h1>
                <button type="button" onClick={executeScroll}>
                  Fale connosco
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 timetable">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                <span>Horários</span>
              </h1>
              <div className="table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr className="bg-light-gray">
                      <th className="text-uppercase">Hora</th>
                      <th className="text-uppercase">Segunda</th>
                      <th className="text-uppercase">Terça</th>
                      <th className="text-uppercase">Quarta</th>
                      <th className="text-uppercase">Quinta</th>
                      <th className="text-uppercase">Sexta</th>
                      <th className="text-uppercase">
                        <div className=" font-size14">10:00-12:00</div>
                        Sábado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle">07:30-08:30</td>
                      <td></td>
                      <td>
                        <span className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kickboxing
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kickboxing
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">08:00-09:30</td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">10:00-12:00</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <span className="bg-purple padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Open Mat
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">12:00-13:30</td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-red padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          No Gi
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-red padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          No Gi
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">16:00-17:30</td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">18:00-19:00</td>
                      <td>
                        <span className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kids
                        </span>
                      </td>
                      <td>
                        <span className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kids
                        </span>
                      </td>
                      <td>
                        <span className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kids
                        </span>
                      </td>
                      <td>
                        <span className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kids
                        </span>
                      </td>
                      <td>
                        <span className="bg-yellow padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kids
                        </span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">19:00-20:30</td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-red padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          No Gi
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-red padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          No Gi
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="align-middle">20:30-22:00</td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kickboxing
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td>
                        <span className="bg-green padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13 font-uppercase">
                          Kickboxing
                        </span>
                      </td>
                      <td>
                        <span className="bg-lightblue padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16 xs-font-size13 font-uppercase">
                          Jiu-Jitsu
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 map">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                <span>Onde Estamos</span>
              </h1>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.364100569984!2d-9.140043384601052!3d38.732406264257584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193350067bc77b%3A0xa276d1224fd82369!2sBrazilian%20fight%20lisboa!5e0!3m2!1sen!2spt!4v1656089747129!5m2!1sen!2spt"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 mb-5 contacts">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 ref={myRef}>
                <span>Fale Connosco</span>
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contact-block">
                <span className="label">Morada</span>
                <p>R. Rebelo da Silva 57A, 1000-257 Lisboa</p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact-block">
                <span className="label">Email</span>
                <p>
                  <a href="mailto:brazilian.fight.lisboa@gmail.com">
                    brazilian.fight.lisboa@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact-block">
                <span className="label">Telefone</span>
                <p>
                  <a href="tel:+351935119955">+351 935 119 955</a>
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact-block">
                <span className="label">Social Media</span>
                <p>
                  <a
                    href="https://www.instagram.com/brazilian.fight.lisboa/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a
                    className="ms-2"
                    href="https://m.facebook.com/Brazilian.fight.Lisboa"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
